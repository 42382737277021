import {checkWithDictionary} from './wordgenerator';
import ResponseStatus from '../../enum/ResponseStatus';
import {handleResponseKeyboard,cleanKeyboard} from './Keyboard/keyboard';

function generateGrid(word) {
    const wordLength = word.length;
    const gridContainer = document.querySelector('.grid-container');
    gridContainer.innerHTML = ''; // Clear any existing grid

    // Je créé une ligne 
    const gridRow = document.createElement('div');
    gridRow.classList.add('grid-row');
    for (let i = 0; i < wordLength; i++) {
        const square = document.createElement('div');
        square.classList.add('grid-item');
        if (i === 0) {
            square.classList.add('letter-input');
            square.classList.add('first-letter');
            square.textContent = word.substring(0, 1);
        }
        else{
            square.textContent = '_';
        }
        gridRow.appendChild(square);
    }
    gridContainer.appendChild(gridRow);
    const gridRow2 = gridRow.cloneNode(true);
    gridRow2.childNodes.forEach(node => node.textContent = "");
    gridRow2.firstChild.classList.remove('first-letter');
    gridRow2.firstChild.classList.remove('letter-input');
    for(let j = 0; j<5; j++){
        gridContainer.appendChild(gridRow2.cloneNode(true));
    }
    cleanKeyboard();
}

function fillGridWithInput(letterPressed){
    const firstGridRowIncomplete = Array
    .from(document.querySelectorAll('.grid-row'))
    .filter(row => !row.classList.contains('completed'))[0];

    if(firstGridRowIncomplete === undefined) return;
    const gridItems = Array
    .from(firstGridRowIncomplete.children)
    .filter(item => !item.classList.contains('letter-input'));

    const gridItem = gridItems[0];
    if(gridItem === undefined) return;

    gridItem.textContent = letterPressed;
    gridItem.classList.add('letter-input');    
}

function removeLetter(){
    const firstGridRowIncomplete = Array
    .from(document.querySelectorAll('.grid-row'))
    .filter(row => !row.classList.contains('completed'))[0];
    if(firstGridRowIncomplete === undefined) return;

    const gridItems = Array
    .from(firstGridRowIncomplete.children)
    .filter(item => item.classList.contains('letter-input'))
    .filter(item => !item.classList.contains('first-letter'));

    if(gridItems.length === 0) return;
    const lengthGridItem = gridItems.length;
    const gridItem = gridItems[lengthGridItem - 1];

    if(gridItem.getAttribute('data-value') !== null){
        const value = gridItem.getAttribute('data-value').toString();
        gridItem.textContent = value; 
    }
    else{
        gridItem.textContent = '_';
    }

    gridItem.classList.remove('letter-input');
}

function wordCompleted(){
    const firstGridRowIncomplete = Array
    .from(document.querySelectorAll('.grid-row'))
    .filter(row => !row.classList.contains('completed'))[0];
    if(firstGridRowIncomplete === undefined) return;

    const gridItems = Array.from(firstGridRowIncomplete.children).filter(item => !item.classList.contains("letter-input"));

    if(gridItems.length > 0) return;

    return Array.from(firstGridRowIncomplete.children).map(item => item.textContent).join('');
}

function emptyRow(){
    const firstGridRowIncomplete = Array
    .from(document.querySelectorAll('.grid-row'))
    .filter(row => !row.classList.contains('completed'))[0];
    if(firstGridRowIncomplete === undefined) return;

    const gridItems = Array.from(firstGridRowIncomplete.children).filter(item => !item.classList.contains('first-letter'));
    gridItems.forEach(item => {
        if(item.getAttribute('data-value') !== null){
            item.textContent = item.getAttribute('data-value').toString();
        }
        else{
            item.textContent = '_';
        }
        item.classList.remove('letter-input');
    });
}

function completeFinalRow(){
    const firstGridRowIncomplete = Array
    .from(document.querySelectorAll('.grid-row'))
    .filter(row => !row.classList.contains('completed'))[0];
    if(firstGridRowIncomplete === undefined) return;

    const gridItems = Array.from(firstGridRowIncomplete.children);
    gridItems.forEach(item => {
        item.classList.add('correct');
    });
    firstGridRowIncomplete.classList.add('finished');
}


async function checkResponse(response, word){
    if(response === word){
        completeFinalRow();
        return ResponseStatus.FINISHED;
    }
    if(await checkWithDictionary(response)){
        handleNextRow(response, word);
        return ResponseStatus.WRONG;
    }
    else{
        emptyRow();
        return ResponseStatus.UNKNOWN;
    }
}

function handleNextRow(response, word) {
    return new Promise((resolve, reject) => {
        const gridRowsIncomplete = Array
            .from(document.querySelectorAll('.grid-row'))
            .filter(row => !row.classList.contains('completed'));

        if(gridRowsIncomplete.length === 1){
            reject("No more rows");
        };

        const firstGridRowIncomplete = gridRowsIncomplete[0];

        const remainingResponse = [];
        const remainingWord = [];

        let correctKeys = [];
        let misplacedKeys = [];
        let wrongKeys = [];

        // First pass: mark correct letters
        for (let i = 0; i < response.length; i++) {
            if (response[i] === word[i]) {
                firstGridRowIncomplete.children[i].classList.add('correct');
                correctKeys.push(response[i]);
            } else {
                remainingResponse.push(response[i]);
                remainingWord.push(word[i]);
            }
        }
        // Second pass: mark misplaced letters
        for (let i = 0; i < remainingResponse.length; i++) {
            const letter = remainingResponse[i];
            const occurrencesInResponse = remainingResponse.slice(0, i).filter(l => l === letter).length;
            const occurrencesInWord = remainingWord.filter(l => l === letter).length;
            if (occurrencesInResponse < occurrencesInWord) {
                const index = Array
                .from(firstGridRowIncomplete.children)
                .findIndex(child => child.textContent === letter && !child.classList.contains('correct') && !child.classList.contains('misplaced'));
                if (index !== -1) {
                    firstGridRowIncomplete.children[index].classList.add('misplaced');
                    misplacedKeys.push(letter);
                }
                else{
                    wrongKeys.push(letter);
                }
            }
            else{
                wrongKeys.push(letter);
            }
        }
        firstGridRowIncomplete.classList.add('completed');
        handleResponseKeyboard(correctKeys, misplacedKeys, wrongKeys);
        resolve();
    }).then(() => {
        buildNextRow();
    }).catch((error) => {
        return;
    });
}

function buildNextRow(){
    const rowsIncomplete = Array
    .from(document.querySelectorAll('.grid-row'))
    .filter(row => !row.classList.contains('completed'));

    if(rowsIncomplete.length === 0) return;

    const firstGridRowIncomplete = rowsIncomplete[0];
    

    const lastGridRowCompleted = Array.from(document.querySelectorAll('.grid-row')).filter(row => row.classList.contains('completed')).pop();

    if(lastGridRowCompleted === undefined) return;

    lastGridRowCompleted.childNodes.forEach((node, index) => {

        // Récupération de la colonne suivante
        const child = firstGridRowIncomplete.children[index];

        // Si c'est la première lettre
        if(index === 0){
            child.classList.add('first-letter');
            child.classList.add('letter-input');
        }

        // Si, sur la ligne précédente, la lettre était correcte 
        if (node.classList.contains('correct') || node.getAttribute('data-value') !== null ) {
            let value = node.textContent;
            if(node.getAttribute('data-value') !== null){
                value = node.getAttribute('data-value').toString();
            }
            child.textContent = value;
            child.setAttribute('data-value', value);
        }
    });

    const children = Array.from(firstGridRowIncomplete.children).filter(child => child.getAttribute('data-value') == null);
    for(let i = 0; i<children.length; i++){
        children[i].textContent = '_';
    }
}




export { generateGrid, fillGridWithInput, removeLetter, wordCompleted, checkResponse} ;