import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import HelpButton from '../../components/HelpButton/HelpButton';
import { useLocation } from 'react-router-dom';
import './MotDePasse.css';
import { Check2,Eye,EyeSlash, XLg, ArrowLeft } from 'react-bootstrap-icons';
import words from '../../content/data/words.json';
import 'bootstrap/dist/css/bootstrap.min.css';


function getRandomWordsFromCategory(wordsObject, category, count) {
  const wordsArray = wordsObject[category];
  if (!wordsArray || wordsArray.length < count) {
    return [];
  }
  const shuffled = [...wordsArray].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
}

function getNewWord(wordsObject, category, existingWords) {
  const wordsArray = wordsObject[category];
  const availableWords = wordsArray.filter(word => !existingWords.includes(word));
  if (availableWords.length === 0) {
    return null;
  }
  const randomIndex = Math.floor(Math.random() * availableWords.length);
  return availableWords[randomIndex];
}



function MotDePasse(){  
  const [randomWords, setRandomWords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isWordVisible, setIsWordVisible] = useState(true);
  const [clues, setClues] = useState(0);
  const [inputValue, setInputValue] = useState(1);
  const location = useLocation();


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const difficulty = searchParams.get('difficulty') || 'facile';
    const selectedWords = getRandomWordsFromCategory(words, difficulty.toLowerCase(), 8);
    setRandomWords(selectedWords);
  }, []);

  const hideWordAfterDelay = () => {
    const timer = setTimeout(() => {
      setIsWordVisible(false);
    },2000);

    return () => clearTimeout(timer);
  }

  
  useEffect(() => {
    const cleanup = hideWordAfterDelay();
    return cleanup;
  }, [currentIndex]);

  const handleNextWord = () => {
    
    if(clues >= 20){
      alert('Fin de la partie');
      return;
    }
    const usedClues = parseInt(inputValue,10);
    if(!isNaN(usedClues) && usedClues >= 1){
      setClues((prevClues) => prevClues + usedClues);
    }
    else{
      return;
    }

    if(clues > 20){
      alert('Fin de la partie');
      return;
    }
    if(currentIndex + 1 < randomWords.length){
      setCurrentIndex((prevIndex) => (prevIndex + 1));
      setIsWordVisible(true);
      setInputValue(1);
    }
    else{
      alert('Fin de la partie');
    }
  }

  const handleWordPassing = () => {
    setClues((prevClues) => prevClues + 1);
    const newWord = getNewWord(words, 'facile', randomWords); 
    if(newWord) {
      setRandomWords((prevWords) => {
        const updatedWords = [...prevWords];
        updatedWords[currentIndex] = newWord;
        setIsWordVisible(true);
        hideWordAfterDelay();
        return updatedWords;
    });
    }
  }

  const handleRevealWord = () => {
    setIsWordVisible(!isWordVisible);
  };

  return (
    <section>

      <div className='row h-100 w-100 gx-0'>
        <div className='col-10 mx-auto d-flex flex-column justify-content-evenly'>
          <div className='row align-items-center justify-content-center'>
            <div className='col-auto'>
                <button className='btn-home' onClick={() => {window.location.href = "/"}} title="Retour à l'accueil">
                    <ArrowLeft size={30} color='white' />
                </button>
            </div>
            <div className='col-auto'>
              <h1 className='text-center fw-bold'>Mot de passe</h1>
            </div>
            <div className='col-auto'>
            </div>
          </div>


          {/* Compteurs */}
          <div className='indicateur-container row align-items-center justify-content-around'>
            <div className='col-auto indicateur'>
              <span className='text-align-center'>{currentIndex+1} mots / 8</span>
            </div>
            <div className='col-auto indicateur'>
              <span className='text-align-center'>{20 - clues} indices / 20</span>
            </div>
          </div>

          <div className="row justify-content-center align-items-center">
            
              <div className="col-xl-6 col-12 word-container mx-5 position-relative mt-4 mt-md-0">
                  <span className='word'>{isWordVisible ? randomWords[currentIndex] : '*****'}</span>
                  <button className="reveal" onClick={handleRevealWord}>
                    {isWordVisible ? <EyeSlash size={30} color='black' /> : <Eye size={30} color='black' />}
                  </button>
              </div>
          </div>
          <div className='row justify-content-center align-items-center'>
            <div className='col-auto'>
              <label htmlFor='inputValue' className='form-label fw-bold'>Nombre d'indices utilisés</label>
                <input 
                type="number" 
                className='form-control' 
                value={inputValue} 
                onChange={(e) => setInputValue(Math.max(1, parseInt(e.target.value, 10)))}  />
              </div>
          </div>
          <div className='row mt-5 mt-md-0 align-items-center justify-content-center'>
            <div className='col-auto'>
              <button className="btn-container pass" onClick={handleWordPassing} title='Passer le mot'>
                <XLg size={45} color='white' />
              </button>
            </div>
            <div className='col-auto '>
              <button className="btn-container validate" onClick={handleNextWord} title='Valider le mot'>
                <Check2 size={45} color='white' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MotDePasse;

