import './MenuButton.css';

function MenuButton({text, onclick, classNameProperty}) {
    function redirectToLink() {
        onclick();
    };
    return (
        <button className={`menu-button m-0 ${classNameProperty}`} onClick={redirectToLink}>
            {text}
        </button>
    );
}

export default MenuButton;