
const ApiUrl = 'https://trouve-mot.fr/api';

const randomRoute = '/sizemax/12';
const wordOfTheDayRoute = '/daily';

function getRandomWordFromApi(){
    return fetch(ApiUrl + randomRoute)
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        return data[0].name;
    })
    .catch(error => {
        console.error('Error:', error);
    });
}

function getWordOfTheDayFromApi() {
    return fetch(ApiUrl + wordOfTheDayRoute)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data.name;
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

function checkWithDictionary(word){
    const dictionaryApi = 'https://www.larousse.fr/dictionnaires/francais/';
    const testWithWord = dictionaryApi + word.toLowerCase();
    return fetch(testWithWord)
        .then(response => {
            const responseUrl = decodeURI(response.url);
            const trimmedUrl = responseUrl.replace(dictionaryApi, '');
            const parts = trimmedUrl.split('/');
            if (parts.length === 2) {
                return true;
            } else {
                return false;
            }
        })
        .catch(error => {
            console.error('Error:', error);
            return false;
        });
}

export { getRandomWordFromApi, getWordOfTheDayFromApi, checkWithDictionary };
