import React from 'react';
import MenuButton from "../../components/menuButton/MenuButton";
import { ArrowLeft, QuestionLg } from 'react-bootstrap-icons';
import Difficulty from '../../enum/Difficulty';

function HandleMotDePasseSelection(){
  document.querySelectorAll('.default').forEach(element => element.classList.add('d-none'));
  document.querySelectorAll('.mdp-selected').forEach(element => element.classList.remove('d-none'));
}

function HandleTusmoSelection(){
  document.querySelectorAll('.default').forEach(element => element.classList.add('d-none'));
  document.querySelectorAll('.tusmo-selected').forEach(element => element.classList.remove('d-none'));
}

function CancelSelection(){
  document.querySelectorAll('.default').forEach(element => element.classList.remove('d-none'));
  document.querySelectorAll('.tusmo-selected').forEach(element => element.classList.add('d-none'));
  document.querySelectorAll('.mdp-selected').forEach(element => element.classList.add('d-none'));
}

function HandleTusmoSoloSelection(){
  window.location.href = window.location.href + 'tusmosolo';
}

function HandleTusmoMotDuJourSelection(){
  window.location.href = window.location.href + 'tusmodujour';
}

function redirectToMotDePasse(difficulty){
  window.location.href = `/motdepasse?difficulty=${difficulty}`;
}

function openRuleMotDePasse(){
  document.querySelector('#rulesModal');
}

function HomePage() {
  return (
    <section>
      <div className='col-10 mx-auto home-page'>
          <div className='row d-flex align-items-center justify-content-center'>
            <div className='col-auto d-none tusmo-selected mdp-selected'>
              <button className='btn-home' onClick={CancelSelection} title="Retour à l'accueil">
                  <ArrowLeft size={30} color='white' />
              </button>
            </div>
            <div className='col-auto'>
              <h1 className='game-title default'>Jeux des mots</h1>
              <h1 className='game-title d-none tusmo-selected'>Tusmo</h1>
              <h1 className='game-title d-none mdp-selected'>Mot de passe</h1>
            </div>
          </div>
          <div className='row gx-0 default'>
            <div className='col d-flex flex-column justify-content-center align-items-center'>
                  <MenuButton text='Tusmo' onclick={HandleTusmoSelection} classNameProperty={'primary mb-5'}/>
                  <MenuButton text='Mot2Passe' onclick={HandleMotDePasseSelection} classNameProperty={'secondary'}/>
            </div>
          </div>
          <div className='row gx-0 d-none tusmo-selected'>
            <div className='col d-flex flex-column justify-content-center align-items-center'>
              <MenuButton text='Mot du jour' onclick={HandleTusmoMotDuJourSelection} classNameProperty={'primary mb-5'}/> 
              <MenuButton text='Solo' onclick={HandleTusmoSoloSelection} classNameProperty={'secondary'}/>
            </div>
          </div>
          <div className = 'row gx-0 d-none mdp-selected'>
            <div className='col d-flex flex-column justify-content-center align-items-center'>
              <button className={`menu-button m-0 rules mb-5`} data-bs-toggle="modal" data-bs-target="#ruleMDPModal" >
                 <span className='question-mark me-2'>?</span> Règles du jeu
              </button>
              <MenuButton text='Facile' onclick={() => {redirectToMotDePasse(Difficulty.FACILE)}} classNameProperty={'secondary mb-5'}/>
              <MenuButton text='Moyen' onclick={() => {redirectToMotDePasse(Difficulty.MOYEN)}} classNameProperty={'tertiary mb-5'}/>
              <MenuButton text='Difficile' onclick={() => {redirectToMotDePasse(Difficulty.DIFFICILE)}} classNameProperty={'primary mb-5'}/>

            </div>  
          </div>
      </div>
      
      <div className="modal fade" id="ruleMDPModal" tabIndex="-1" aria-labelledby="rulesModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="rulesModalLabel">Règles du jeu</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div className="modal-body">
                <div className='row mb-2'>
                    <div className='col-3 fw-bold'>
                        Nombre de joueurs :
                    </div>
                    <div className='col'>
                        2 (Un joueur qui donne les indices et un joueur qui devine)
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-3 fw-bold'>
                        But du jeu : 
                    </div>
                    <div className='col'>
                        Faire deviner le plus de mots possible à son partenaire avec un maximum de 20 indices au total.
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-3 fw-bold'>
                        Préparation :
                    </div>
                    <div className='col'>
                        Choisissez un joueur qui donne les indices et un autre qui devine.
                        Le joueur qui donne les indices est celui qui peut lire la liste de mots à faire deviner.
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-3 fw-bold'>
                        Déroulement du jeu :
                    </div>
                    <div className='col'>
                        Le joueur qui donne les indices peut dire un mot à son partenaire pour lui faire deviner le mot cible. <br/>
                        Le joueur qui devine propose un mot en réponse. <br/>
                        Si le mot est correct, le joueur qui devine passe au mot suivant de la liste. <br/>
                        Si le mot est incorrect, le joueur qui donne les indices peut proposer un autre indice (ou passer à un autre mot). 
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-3 fw-bold'>
                        Les contraintes :
                    </div>
                    <div className='col'>
                        Les joueurs ont un total de 20 mots (ou indices) maximum pour faire deviner toute la liste. <br/>
                        Chaque mot utilisé par le joueur qui donne les indices compte comme un indice. Par exemple, s'il utilise 3 mots pour faire deviner un mot cible, il lui reste 17 indices pour le reste de la partie. <br/>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-3 fw-bold'>
                        Fin de la partie :
                    </div>
                    <div className='col'>
                        La partie se termine lorsque tous les mots ont été devinés ou si les joueurs ont utilisé leurs 20 indices.<br/>
                        Si les joueurs devinent tous les mots avant de dépasser 20 indices, ils gagnent la partie !<br/>
                        Si les 20 indices sont utilisés avant que tous les mots ne soient trouvés, ils perdent. <br/>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-3 fw-bold'>
                        Les interdits :
                    </div>
                    <div className='col'>
                        Un indice ne peut pas avoir la même racine que le mot à faire deviner. <br/>
                        Un indice ne peut pas être la traduction du mot dans une autre langue. <br/>
                        Ne pas utiliser d'acronyme. <br/>
                        Un indice est composé d'un seul et unique mot. <br/>
                        Si un mot est composé de plusieurs mots, il est interdit de donner un indice pour chaque mot. <br/>
                        Si pour une raison ou une autre vous passez un mot, vous ne pouvez pas revenir dessus, et un malus d'un indice vous sera appliqué.
                    </div>
                </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomePage;