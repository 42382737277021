import './wordgenerator';
import './gameService';
import React, { useState, useEffect} from 'react';
import {getRandomWordFromApi, getWordOfTheDayFromApi} from './wordgenerator';
import {generateGrid, fillGridWithInput,removeLetter, wordCompleted, checkResponse } from './gameService';
import './Tusmo.css';
import Keyboard from './Keyboard/keyboard';
import ResponseStatus from '../../enum/ResponseStatus';
import confettisImage from '../../images/confettis.gif';
import { ArrowLeft } from 'react-bootstrap-icons';

function Tusmo({ gamePlay }) {
    const [word, setWord] = useState('');
    const [answers, setAnswers] = useState(0);
    const [points, setPoints] = useState(0);
    useEffect(() => {
        let isMounted = true;
        const fetchWord = async () => {
            await generateNewWordAndGrid(isMounted);
        };
        fetchWord();
        return () => {
            isMounted = false;
        };
    }, [gamePlay]);

    const handleInput = async (event) => {
        if (event.keyCode === 8) {
            removeLetter();
            return;
        }

        if (event.keyCode === 13) {
            await handleResponse();
        }

        const letterPressed = event.key.toUpperCase();
        if (letterPressed.length === 1 && letterPressed.match(/[A-Z]/)) {
            fillGridWithInput(letterPressed);
        }
    };

    const handleVirtualKeyBoardInput = async function (key) {
        if (key === '⌫') {
            removeLetter();
            return;
        }

        if (key === '↵') {
            await handleResponse();
        }

        if (key.length === 1 && key.match(/[A-Z]/)) {
            fillGridWithInput(key);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => handleInput(event);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [word]);

    return (
        <section>
            <img src={confettisImage} alt='Confettis' className='confettis' />
            <div className='spinner-container d-none'>
                <div class="spinner-border" role="status">
                </div>
            </div>
            <div className='page-container h-100 py-4'>
                <div className='d-flex flex-column align-items-center justify-content-md-between h-100'>
                    <div className='row align-items-center justify-content-center my-md-0 my-2'>
                        <div className='col-auto'>
                            <button className='btn-home' onClick={() => {window.location.href = "/"}} title="Retour à l'accueil">
                                <ArrowLeft size={30} color='white' />
                            </button>
                        </div>
                        <div className='col-auto'>
                            <h1 className='game-title'>Tusmo</h1>
                        </div>
                    </div>
                    {gamePlay !== "jour" ?                    
                     <div className='row my-md-0 my-2'>
                        <div className='col-auto'>
                            <h3 className='points'>Grilles complétées : {points}</h3>
                        </div>
                    </div> : null }

                    <div id="word-response" className='row d-none my-md-0 my-2'>
                        <div className='col-auto'>
                            <h3 className='m-0'></h3>
                        </div>
                    </div>
                    <div className='grid-container my-md-0 my-2'>
                    </div>
                    <Keyboard onclickFunction={handleVirtualKeyBoardInput} />
                </div>
            </div>
        </section>
    );

    async function handleResponse() {
            const response = wordCompleted();
            document.querySelector('.spinner-container').classList.remove('d-none');
            if (!response) return;
            const responseStatus = await checkResponse(response, word);
            if (responseStatus === ResponseStatus.FINISHED) {
                setPoints(prevPoints => prevPoints + 1);
                
                document.querySelector('.spinner-container').classList.add('d-none');
                document.querySelector('.confettis').style = 'display: block';
                setTimeout(async () => {
                    if(gamePlay === 'jour'){
                        window.location.href = "/";
                        return;
                    }
                    await generateNewWordAndGrid(true);
                } ,5000);
                
                return;
            }
            if(responseStatus === ResponseStatus.WRONG){
                setAnswers(prevAnswers => {
                    const newAnswers = prevAnswers + 1;
                    if (newAnswers === 6) {
                        document.getElementById('word-response').classList.remove('d-none');
                        document.getElementById('word-response').textContent = "Le mot était : " + word;
                        setTimeout(async () => {
                            document.getElementById('word-response').classList.add('d-none');
                            generateNewWordAndGrid(true);
                        }, 2500);
                    }
                    return newAnswers;
                });
            }
            document.querySelector('.spinner-container').classList.add('d-none');
            return;
    }

    async function generateNewWordAndGrid(isMounted) {
        let newWord;
        if (gamePlay === 'solo') {
            newWord = await getRandomWordFromApi();
        } else if (gamePlay === 'jour') {
            newWord = await getWordOfTheDayFromApi();
        }
        if (isMounted) {
            newWord = newWord.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
            setWord(newWord);
            generateGrid(newWord);
            setAnswers(0);
            document.querySelector('.confettis').style = 'display: none';
        }
    }
}

export default Tusmo;