import React from 'react';
import './Keyboard.css';

const Keyboard = ({onclickFunction}) => {
    const keys = [
        ['A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M'],
        ['⌫', 'W', 'X', 'C', 'V', 'B', 'N', '↵']
    ];

    return (
        <div className="keyboard d-flex flex-column w-100 mb-2 mt-5 mt-md-0">
            {keys.map((row, rowIndex) => (
                <div key={rowIndex} className="keyboard-row d-flex">
                    {row.map((key, keyIndex) => (
                        <div
                            key={keyIndex}
                            data-value={key}
                            className={`keyboard-key ${rowIndex === 2 && (keyIndex === 0) ? 'flex-2 return' : rowIndex === 2 && keyIndex === 7 ? 'flex-2 enter' : ''}`}
                            onClick={() => onclickFunction(key)}
                        >
                            {key}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};


function handleResponseKeyboard(correctKeys, misplacedKeys, wrongKeys){
    for(const key of correctKeys){
        const keyElement = document.querySelector(`.keyboard-key[data-value="${key}"]`);
        keyElement.classList.remove('misplaced-key');
        keyElement.classList.add('correct-key');
    }

    for(const key of misplacedKeys){
        const keyElement = document.querySelector(`.keyboard-key[data-value="${key}"]`);
        if(!keyElement.classList.contains('correct-key')){
            keyElement.classList.add('misplaced-key');
        }
    }

    for(const key of wrongKeys){
        const keyElement = document.querySelector(`.keyboard-key[data-value="${key}"]`);
        if(!(keyElement.classList.contains('correct-key') || keyElement.classList.contains('misplaced-key'))){
            keyElement.classList.add('wrong-key');
        }
    }
}

function cleanKeyboard(){
    const keys = document.querySelectorAll('.keyboard-key');
    keys.forEach(key => {
        key.classList.remove('correct-key');
        key.classList.remove('misplaced-key');
        key.classList.remove('wrong-key');
    });
}

export { handleResponseKeyboard,cleanKeyboard };
export default Keyboard;