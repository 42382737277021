import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import MotDePasse from './pages/mot2passe/MotDePasse';
import Tusmo from './pages/Tusmo/Tusmo';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/motdepasse" element={<MotDePasse/>}/>
        <Route path="/tusmodujour" element={<Tusmo gamePlay={'jour'}/>}/>
        <Route path="/tusmosolo" element={<Tusmo gamePlay={'solo'}/>}/>
      </Routes>
    </Router>
  );
}

export default App;
